<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {timeUtil} from "@/helpers/time-util";
import {currencyHelper} from "@/helpers/currency-helper";
import Swal from "sweetalert2";

/**
 * MarketPlace Component
 */
export default {
  computed: {
    currencyHelper() {
      return currencyHelper
    },

    timeUtil() {
      return timeUtil
    },

    getSelectedShopId() {
      return this.$store ? this.$store.state.shop.shopId : '';
    }
  },

  components: {
    Layout,
    PageHeader
  },

  data() {
    return {
      products: null,
      productVariants: null,

      modals: {

        buyModal: {

          visible: false,

          product: null,
          variationId: ""

        }
      }
    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('marketplace.title'),
          active: true
        }
      ]
    },

    async loadProducts() {
      const result = await axios.get(`/marketplace/product/list`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      });

      this.products = result.data
    },

    async openBuyModal(product) {
      this.modals.buyModal.visible = true
      this.modals.buyModal.product = product

      await this.loadProductVariations(product.type)
    },

    hideBuyModal() {
      this.modals.buyModal.visible = false
      this.modals.buyModal.product = null
      this.productVariants = null
    },

    loadProductVariations(productType) {
      axios.get(`/marketplace/product/variation/list`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "type": productType,
        }
      }).then(result => {
        this.productVariants = result.data
      }).catch(error => {
        errorCatcher.catchErrors(error)
      });
    },

    createOrder() {
      if (!this.modals.buyModal.variationId) {
        Swal.fire(this.$t('message.error'), "Wybierz produkt!", "error");
        return
      }

      if (!this.getSelectedShopId) {
        Swal.fire(this.$t('message.error'), "Wybierz sklep!", "error");
        return
      }

      let json = JSON.stringify({
        "marketplaceProductVariationId": this.modals.buyModal.variationId,
      });

      if (this.modals.buyModal.product.type === 'SHOP_PRODUCT_LIMIT_INCREASE' || this.modals.buyModal.product.type === 'WORDPRESS_PLUGIN') {
        json = JSON.stringify({
          "marketplaceProductVariationId": this.modals.buyModal.variationId,
          "metadata": JSON.stringify({
            "shopId": this.getSelectedShopId
          })
        });
      }

      this.hideBuyModal()
      axios.post(`/marketplace/order`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        window.location = `/dashboard/marketplace/payment/${result.data.marketplaceOrderId}`
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    getText(variation) {
      let text = this.modals.buyModal.product.name
      if (variation && variation.title) {
        text += ` o ${variation.title}`
      }

      return text
    }
  },

  async created() {
    try {
      await this.loadProducts()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('marketplace.title')" :items="getItems()"/>
    <div class="row">
      <div v-for="(product, index) in products" :key="index" class="col-12 col-xl-4 my-3 card-group">
        <div class="card">
          <img :src="product.imageUrl" class="card-img-top" alt="..."/>
          <div class="card-body d-flex flex-column">
            <div>
              <h5 class="font-size-16">{{ product.name }}</h5>
            </div>
            <p class="card-text flex-grow-1">{{ product.description }}</p>
            <div class="text-center py-2">
              <b-button variant="primary" @click="openBuyModal(product)" style="width: 100%">
                <i class="mdi mdi-shopping font-size-15 mr-1"></i>
                Zakup
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        size="xl"
        id="modal-1"
        v-model="modals.buyModal.visible"
        title="Wybierz wersje produktu"
        title-class="font-18"
        hide-footer
        @hide="hideBuyModal"
        @esc="hideBuyModal">

      <template v-if="modals.buyModal.product && productVariants">
        <div v-for="(variation, index) in productVariants" :key="index">
          <input type="radio" :id="variation.id" :value="variation.id" v-model="modals.buyModal.variationId"
                 :name="variation.id">
          <label :for="variation.id" style="margin-left: 5px;">
            {{ getText(variation) }}

            <span v-if="variation.discount > 0">
              (<s>{{ variation.priceWithTax.toFixed(2) }}{{
                currencyHelper.getCurrencyInfo(variation.currency).symbol
              }}</s> {{
                variation.priceWithDiscount.toFixed(2)
              }}{{ currencyHelper.getCurrencyInfo(variation.currency).symbol }})
            </span>
            <span v-else>
              ({{ variation.priceWithTax.toFixed(2) }}{{ currencyHelper.getCurrencyInfo(variation.currency).symbol }})
            </span>

            <span v-if="modals.buyModal.product.subscription">
            => {{ variation.subscriptionTime }} {{ timeUtil.getHumanDay(variation.subscriptionTimeUnit) }} (Subskrybcja)
            </span>
          </label>
        </div>
      </template>

      <hr>
      <div class="float-right">
        <b-button variant="primary" @click="createOrder">Potwierdź</b-button>
      </div>
    </b-modal>
  </Layout>
</template>